import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Table from 'src/components/Table';
import Icon from 'src/elements/Icon';
import Card from 'src/layout/Card';
import { formatPrice } from 'src/utils/Util';
import { CustomerInvoice, createCustomerInvoice } from 'src/utils/api/customer-invoices/CustomerInvoices';
import useBillableSellers from 'src/utils/api/customer-invoices/hooks/useBillableSellers';
import useStoredState from 'src/utils/hooks/useStoredState';
import styled from 'styled-components';

export default function BillableCustomersCard() {

	const history = useHistory();

    const [_after, setAfter] = useStoredState('_after_BillableCustomersCard', undefined as any)
    const [_before, setBefore] = useStoredState('_before_BillableCustomersCard', undefined as any)

    const after = _after ? new Date(_after) : undefined;
    const before = _before ? new Date(_before) : undefined;

	const { billableSellers, loading } = useBillableSellers({
        after,
        before,
    });

	function handleCreateCustomerInvoice(customerNumber: number) {
		return async () => {

			try {
				const invoice = await createCustomerInvoice(customerNumber);
				history.push(`/customer-invoices/${invoice.id}`);
			} catch (e) {
				console.error('Failed to create customer invoice', e);
				alert('Kunde inte skapa faktura');
				return;
			}
		}
	}

    if (loading) {
        return (
			<div style={{ paddingTop: 100, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
        )
    }


    return (
        <Style size="full">
            <div className="header">
                <h1>Att fakturera</h1>
                <div className="spacer auto"></div>

                <div className="field-wrapper">
                    <label>Från</label>
                    <input type="date" value={after ? after.toLocaleDateString('sv-SE') : ''} onChange={(e) => setAfter(new Date(e.target.value))} max={new Date().toISOString().split('T')[0]} />
                </div>

                <div className="spacer ws"></div>

                <div className="field-wrapper">
                    <label>Till</label>
                    <input type="date" value={before ? before.toLocaleDateString('sv-SE') : ''} onChange={(e) => setBefore(new Date(e.target.value))} max={new Date().toISOString().split('T')[0]} />
                </div>

            </div>

            <div className="spacer m"></div>

            {billableSellers.length !== 0 && <Table
                data={billableSellers}
                structure={[
                    {
                        heading: 'Kund',
                        key: 'customerName',
                    },
                    {
                        heading: 'Summa',
                        key: 'totalGrossAmount',
                        component: (row: CustomerInvoice) => {
                            return row.totalGrossAmount ? formatPrice(Math.round(row.totalGrossAmount)) : '-';
                        },
                    },
                    {
                        heading: '',
                        className: 'row-icons',
                        component: (row: CustomerInvoice) => {
                            return (
                                <div>
                                    <Icon onClick={handleCreateCustomerInvoice(row.customerNumber)}>add</Icon>
                                </div>
                            );
                        },
                    },
                ]}
            />}

            {billableSellers.length === 0 && <>
            
                {before === undefined && after === undefined && <p>Det finns ingen kund att fakturera</p>}

                {(before !== undefined || after !== undefined) && <div>
                    <p>Det finns ingen kund att fakturera mellan {after?.toLocaleDateString()} och {before?.toLocaleDateString()}</p>
                    <div className="spacer s"></div>
                    <button onClick={() => { setAfter(undefined); setBefore(undefined) }}>Återställ</button>
                </div>}
            
            </>}

        </Style>
    )
}

const Style = styled(Card)`

    .header {
        .field-wrapper {
            display: flex;
            flex-direction: column;
        }
    }

    .row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;