import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import styled from 'styled-components';
import PNTrading from 'src/pn-trading-sdk';
import { formatPrice, getPaymentGroup } from '../../utils/Util';
import { Link } from 'react-router-dom';
import { PaymentMethod } from 'src/utils/api/Invoices';

type SummarizedItem = {
	id: number;
	readable_id: string;
	invoice_id: number;
	name: string;
	seller: string;
	net_amount: number;
	vat_amount: number;
	service_fee: number;
	is_paid: boolean;
	primary_payment_method: string;
}

export default function PageAuctionSummary(props: any) {
	let id = props.match.params.id;

	const [items, setItems] = useState<null | SummarizedItem[]>(null)
	const [auction, setAuction] = useState({ id: 0, name: '', status: '', ending: '', slug: '' });

	useEffect(() => {
		PNTrading.get(`/v2/summaries/auction-summary/${id}`).then(d => {
			setItems(d.items)
			setAuction(d.auction)
		});
	}, [id]);

	if (!items)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);


	const paidObjects = items.filter((o) => o.is_paid);
	const notPaidObjects = items.filter((o) => !o.is_paid);

	const totalBids = items.reduce((tot, o) => tot + o.net_amount, 0);
	const paidBids = paidObjects.reduce((tot, o) => tot + o.net_amount, 0);
	const paymentsTotal = paidObjects.reduce((tot, o) => tot + o.net_amount + o.vat_amount, 0);

	const serviceFee = paidObjects.reduce((tot, o) => tot + o.service_fee, 0) * 1.25;

	const getPaidAmountByPaymentMethod = (method: string) => {
		return paidObjects.filter((o) => o.primary_payment_method === method).reduce((tot, o) => tot + o.net_amount + o.vat_amount + o.service_fee, 0);
	}

	const objectRow = (o: SummarizedItem) => (
		<tr key={o.id}>
			<td>
				{o.readable_id}
			</td>
			<td>{o.name}</td>
			<td>{o.seller}</td>
			<td>{formatPrice(o.net_amount)}</td>
			<td>{o.is_paid && o.primary_payment_method ? getPaymentGroup({
				type: o.primary_payment_method
			}) : '-'}</td>
			<td>{o.is_paid && <Link to={`/invoices/${o.invoice_id}`}>{o.invoice_id}</Link>}</td>
		</tr>
	);

	const sort = (a: SummarizedItem, b: SummarizedItem) => {
		const idA = Number(a.readable_id.split('/')[0]);
		const idB = Number(b.readable_id.split('/')[0]);

		if (idA < idB) return -1;
		if (idA > idB) return 1;

		return 0;
	}

	return (
		<Style>
			<Card size="medium" className="hide-print">
				<h2>Auktionssummering</h2>
				<h5>(denna ruta visas inte vid utskrift)</h5>
				<div className="spacer s"></div>
				<p>Denna sida kan skrivas ut direkt i webbläsaren, antingen som en PDF eller via skrivare. Summeringen baseras på inbetalningar och avprickade objekt.</p>
				<div className="spacer s"></div>
				<button onClick={() => window.print()}>Skriv ut</button>
			</Card>

			<Card size="full" className="scale-down-mobile">
				<h2>
					<strong>{auction.name}</strong>
				</h2>
				<div className="spacer m"></div>

				<h4>Betalda objekt ({paidObjects.length} st)</h4>
				<div className="spacer xs"></div>
				<table className="objects-table">
					{tableHead()}
					<tbody>
						{paidObjects.sort(sort).map(objectRow)}
					</tbody>
				</table>

				<div className="spacer s"></div>

				<table className="totals">
					<tbody>
						<tr>
							<th>Bud (hela auktionern):</th>
							<td></td>
							<td>{formatPrice(totalBids)}</td>
						</tr>

						<tr>
							<th>Bud (betalade objekt):</th>
							<td></td>
							<td>{formatPrice(paidBids)}</td>
						</tr>

						<tr>
							<th>Totalt i kassan (inkl. moms):</th>
							<td></td>
							<td>{formatPrice(paymentsTotal + serviceFee)}</td>
						</tr>

						<tr className="sub-row">
							<td></td>
							<th>Swish:</th>
							<td>{formatPrice(getPaidAmountByPaymentMethod(PaymentMethod.InvoiceSwish) + getPaidAmountByPaymentMethod(PaymentMethod.OnsiteSwish))}</td>
						</tr>

						<tr className="sub-row">
							<td></td>
							<th>Kort:</th>
							<td>{formatPrice(getPaidAmountByPaymentMethod(PaymentMethod.OnsiteCard))}</td>
						</tr>

						<tr className="sub-row">
							<td></td>
							<th>Kontant:</th>
							<td>{formatPrice(getPaidAmountByPaymentMethod(PaymentMethod.OnsiteCash))}</td>
						</tr>

						<tr className="sub-row">
							<td></td>
							<th>Bank:</th>
							<td>{formatPrice(getPaidAmountByPaymentMethod(PaymentMethod.InvoiceBank))}</td>
						</tr>

						<tr>
							<th>Varav serviceavgift (inkl. moms):</th>
							<td></td>
							<td>{formatPrice(serviceFee)}</td>
						</tr>
					</tbody>
				</table>

				{notPaidObjects.length !== 0 && (
					<>
						<div className="spacer l"></div>
						<h4>Ej betalade objekt ({notPaidObjects.length} st)</h4>
						<div className="spacer xs"></div>
						<table className="objects-table">
							{tableHead()}
							<tbody>
								{notPaidObjects.sort(sort).map(objectRow)}
							</tbody>
						</table>
					</>
				)}
			</Card>
		</Style>
	);
}

const tableHead = () => (
	<thead>
		<tr>
			<th>ID</th>
			<th>Namn</th>
			<th>Säljare</th>
			<th>Bud</th>
			<th>Betalmetod</th>
			<th>Betalning ID</th>
		</tr>
	</thead>
);

const Style = styled(Page)`
	.objects-table {
		background-color: White;
		font-size: 12px;
		width: 100%;
		border-collapse: collapse;

		th {
			padding: 3px 10px;
			text-align: left;
		}

		td {
			padding: 3px 10px;
			border-color: #666666;
			border-width: 1px;
			border-style: Solid;
		}
	}

	table.totals {
		background-color: White;
		font-size: 12px;
		border-collapse: collapse;

		margin-left: auto;

		th:last-child,
		td:last-child {
			padding-right: 10px;
		}

		th {
			padding: 3px 20px;
			text-align: left;
		}

		td {
			padding: 3px 20px;
		}

		.sub-row {
			th {
				font-weight: 200;
			}
		}
	}
`;
