import PNTrading from 'src/pn-trading-sdk';
import React from 'react';
import Icon from '../../elements/Icon';
import { UseInvoiceInvoice } from 'src/utils/api/Invoices';

export default function InvoicePrintButton({ invoice }: { invoice: UseInvoiceInvoice }) {

	return !invoice ? null : (
		<button
			className="icon-button nostyle"
			disabled={!invoice.isValid()}
			onClick={async () => {
				console.log(invoice)
				if (!invoice.isValid()) {
					alert('Fakturan saknar information');
					return;
				}

				let file = await PNTrading.get(`/v2/invoices/${invoice.id}/pdf`);
				setTimeout(() => {
					if (file.data) {
						window.open(`${PNTrading.getURL()}${file.data}`, '_blank')?.focus();
					}
				}, 500);
			}}
		>
			<Icon>print</Icon>
			<span>Skriv ut</span>
		</button>
	);
}
