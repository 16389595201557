import React from 'react';
import Icon from "../../elements/Icon";
import { InvoiceItem, UseInvoiceInvoice } from "src/utils/api/Invoices";

export default function DeleteInvoiceItemButton({ item, invoice }: { item: InvoiceItem, invoice: UseInvoiceInvoice }) {

    async function handleClick() {
        let confirm = window.confirm("Är du säker på att du vill ta bort objektet från fakturan?");

        if (!confirm) return;

        try {
            await invoice.removeItem(item.id);
        } catch (error: any) {

            alert('Ett fel uppstod');
            console.error(error);
        }

    }

    return (
        <button className="secondary black" onClick={handleClick}>
            <Icon>delete_outline</Icon>
        </button>
    )
}