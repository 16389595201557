import React from 'react';
import styled from 'styled-components';

/**
 * 
 * @param {any} param0
 * @returns 
 */
export default function Icon({ children, style = undefined, type = '', className = "", onClick = () => {}, disabled = false }) {
	return (
		<Style
			onClick={(e) => {
				if (!disabled) onClick(e);
			}}
			style={style}
			className={`icon material-icons${type} ${className ? className : ''} ${disabled ? 'disabled' : ''}`}
		>
			{children}
		</Style>
	);
}

const Style = styled.i`
	cursor: pointer;
	user-select: none;
	color: #777;
	font-size: inherit;
	display: inline;

	&.disabled,
	&[disabled] {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;
