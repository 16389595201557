import PNTrading from 'src/pn-trading-sdk';
import React, { useState } from 'react';
import Icon from '../../elements/Icon';
import { UseInvoiceInvoice } from 'src/utils/api/Invoices';

export default function InvoiceSendReceiptButton({ invoice }: { invoice: UseInvoiceInvoice }) {
	const [loading, setLoading] = useState(false);

	return !invoice ? null : (
		<button
			className="icon-button nostyle"
			disabled={!invoice.isValid()}
			onClick={async () => {
				if (!invoice.isValid()) {
					alert('Fakturan saknar information');
					return;
				}

				setLoading(true);
				//TODO Update this.
				let resp = await PNTrading.post(`/v2/invoices/${invoice.id}/pdf`);
				setLoading(false);

				if (resp.success === false) {
					alert('Error: ' + resp.error);
					return;
				}
			}}
		>
			<Icon>mail</Icon>
			{loading && <div className="loader" style={{ fontSize: '1.2rem', marginLeft: '2rem', marginRight: '2rem' }}></div>}
			{!loading && <span>Skicka kvitto</span>}
		</button>
	);
}
