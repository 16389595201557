import PNTrading from 'src/pn-trading-sdk';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Table from '../../components/Table';
import Icon from '../../elements/Icon';
import Card from '../../layout/Card';
import Page from '../../layout/Page';
import Auctions from '../../utils/api/Auctions';
import { formatPrice } from '../../utils/Util';
import { Link } from 'react-router-dom';
import { SendWinningMailToAllButton } from './components/SendWinningMailToAllButton';
import { SendWinningMailToUserButton } from './components/SendWinningMailToUserButton';
import usePopup from '../../utils/hooks/usePopup';
import Alert from '../../layout/popups/Alert';
import { SendReminderMailToUserButton } from './components/SendReminderMailToUserButton';

export default function PagePayAuction(props) {
	let id = props.match.params.id;

	const [auction, setAuction] = useState();
	const [users, setUsers] = useState();

    const [open] = usePopup()

	const history = useHistory();

	useEffect(async () => {
		let auction = await Auctions.get(id);
		let auctionPayment = await PNTrading.get(`/auctions/${id}/pay?embed=false`);

		setAuction(auction);
		setUsers(auctionPayment.users.sort((a, b) => a.username.localeCompare(b.username)));
	}, [id]);

	const createPayment = async (row) => {
		try {

			let payment = await PNTrading.get(`/auctions/${id}/pay/${row.id}`);

			if (payment.id) {
				history.push(`/invoices/${payment.id}`);
			} else {
				alert(payment);
			}
		} catch(e) {
			if (e?.response?.error == 'no_objects') {
				open(<Alert 
					title={`Betalningen kunde inte skapas!`}
					text={'Objekten som denna användare har vunnit är borttagna ur systemet av en administratör och/eller kan inte hittas!'}
					confirmText={'Okej'}
				/>)
			}
		}
	};

	if (!auction || !users)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<Style>
			<Card size="full">
				<div className="row center">
					<h2>
						Skapa betalning för <strong>{auction.name}</strong>
					</h2>

					<div className="spacer auto"></div>

					<SendWinningMailToAllButton id={id} />
					<div className="spacer ws"></div>
					<Link className="button" to={`/lists/auction-summary/${auction.id}`}>
						Visa sammanställning
					</Link>
				</div>
				<div className="spacer m"></div>

				<Table
					data={users}
					tableID={`pay-auction-${id}`}
					structure={[
						{
							heading: 'ID',
							key: 'id',
						},
						{
							heading: 'Username',
							key: 'username',
						},
						{
							heading: 'Email',
							key: 'email',
						},
						{
							heading: 'Phone',
							key: 'phone',
						},
						{
							heading: 'Status',
							component: (row) => {
								return {
									undefined: <div>-</div>,
									unpaid: <div className="badge red">OBETALD</div>,
									draft: <div className="badge gray">UTKAST</div>,
									paid: <div className="badge green">BETALD</div>,
								}[row.payment?.status];
							},
						},
						{
							heading: 'Totalt',
							component: (row) => {
								return <div>{row.payment ? formatPrice(Math.ceil(row.payment.total_net_amount + row.payment.total_vat_amount)) : '-'}</div>;
							},
						},
						{
							heading: '',
							className: 'row-icons',
							component: (row) => {
								return (
									<div>

										<SendReminderMailToUserButton user={row.id} username={row.username} />

										<SendWinningMailToUserButton id={id} user={row.id} username={row.username} />

										{row.payment && (
											<>
												<Icon onClick={() => history.push(`/invoices/${row.payment.id}`)}>receipt</Icon>
											</>
										)}
										{!row.payment && (
											<>
												<Icon onClick={() => createPayment(row)}>add</Icon>
											</>
										)}
									</div>
								);
							},
						},
					]}
				/>
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;
