import React from "react";
import { UseInvoiceInvoice } from "src/utils/api/Invoices";
import styled from "styled-components";

export default function SetInvoiceUser({ invoice }: { invoice: UseInvoiceInvoice }) {

    function onSubmit(e: React.FormEvent) {
        e.preventDefault();

        const data = new FormData(e.target as any);

        invoice.update({
            user_id: parseInt(data.get('user_id') as string)
        }).then(() => {
            alert('Fakturan har kopplats till användaren');
        }).catch((e) => {
            alert(e.message);
        });
    }

    return (
        <Style onSubmit={onSubmit}>
            <div className="spacer xs"></div>
            <h3>Koppla mot användare</h3>
            <div className="spacer xs"></div>
            <p>Detta kommer att koppla denna faktura mot användaren samt hämta uppgifterna och fylla i dem nedan. Har redan uppgifter fyllts i så kommer de skrivas över.</p>
            
            <div className="spacer s"></div>
            <div className="input-column">
                <label>Användar ID *</label>
                <div className="spacer xs"></div>
                <div className="row">
                    <input required type="number" name="user_id" defaultValue={invoice.buyer_id ?? ''} />
                </div>
            </div>
            <div className="spacer s"></div>
            <button type="submit">Koppla</button>
            <div className="spacer m"></div>
        </Style>
    )
}

const Style = styled.form`

`;