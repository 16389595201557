import React from 'react';
import styled from 'styled-components';
import BuyerCard from '../../components/invoices/BuyerCard';
import InvoicePrintButton from '../../components/invoices/InvoicePrintButton';
import InvoiceSendReceiptButton from '../../components/invoices/InvoiceSendReceiptButton';
import InvoiceStatus from '../../components/invoices/InvoiceStatus';
import InvoiceTable from '../../components/invoices/InvoiceTable';
import Card from '../../layout/Card';
import Page from '../../layout/Page';
import MarkAsPayed from '../../layout/popups/invoice/MarkAsPayed';
import { useInvoice } from '../../utils/api/Invoices';
import usePopup from '../../utils/hooks/usePopup';
import ShowPaymentQRButton from '../../components/invoices/ShowPaymentQRButton';
import MarkAsReady from 'src/components/invoices/MarkAsReadyPopup';
import PaymentsCard from 'src/components/invoices/PaymentsCard';

/**
 

TODO:

Makulera faktura / avbryta betalning

*/

export default function PageInvoice(props: any) {
	let { id } = props.match.params; // invoice id
	const { invoice } = useInvoice(id); // invoice object

	const [open] = usePopup(); // open popup	

	if (!invoice)
		return (
			<>
				<div className="spacer l"></div>
				<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<div className="loader"></div>
				</div>
			</>
		);

	return (
		<>
			<TopNav>
				<InvoiceStatus status={invoice.status} />
				<div className="spacer auto"></div>
				<ShowPaymentQRButton invoice={invoice} />
				<InvoicePrintButton invoice={invoice} />

				<InvoiceSendReceiptButton invoice={invoice} />

				{invoice.status === 'draft' && (
					<>
						<div className="spacer wm"></div>
						<button
							className="outlined"
							onClick={() => {

								open(<MarkAsReady invoice={invoice} />);
							}}
						>
							Markera som redo
						</button>
					</>
				)}

				{invoice.status === 'unpaid' && (
					<>
						<div className="spacer wm"></div>
						<button
							onClick={() => {
								if (!invoice.isValid()) {
									alert('Fakturan saknar information');
									return;
								}

								open(<MarkAsPayed invoice={invoice} />);
							}}
						>
							Markera som betald
						</button>
					</>
				)}
			</TopNav>

			<Style>
				<Card size="full">
					<InvoiceTable invoice={invoice} />
				</Card>

				<BuyerCard invoice={invoice} />
				<PaymentsCard invoice={invoice} />

			</Style>
		</>
	);
}

const TopNav = styled.div`
	width: 1500px;
	max-width: 100%;
	margin: auto;
	padding-left: 2rem;
	padding-right: 2rem;
	padding-top: 1.5rem;

	display: flex;
	justify-content: flex-end;
	align-items: center;

	.icon-button {
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-left: 3rem;

		.icon {
			font-size: 1.5rem;
			margin-right: 0.5rem;
		}

		span {
			font-size: 1rem;
			color: var(--color-link);
		}
	}

	.mobile & {
		flex-wrap: wrap;
		.spacer.auto {
			width: 100%;
			height: 1rem;
		}
	}
`;

const Style = styled(Page)`
	.input-column {
		display: flex;
		flex-direction: column;

		.row {
			width: 100%;

			input {
				flex: 1;
				min-width: 0;
			}
		}
	}
`;
