import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import Checkbox from '../../elements/Checkbox';
import styled from 'styled-components';
import PNTrading from 'src/pn-trading-sdk';
import { calculateServiceFee, calculateServiceFeeFromInvoiceItems, caluclateCommissionAmount, formatPrice, getLocalDate } from '../../utils/Util';
import useSessionState from '../../utils/hooks/useSessionState';
import AuctionSelect from 'src/components/auction/AuctionSelect';

export default function PageProjectSummary(props) {
	let id = props.match.params.id;

	// Filter states.
	const [commission, setCommission] = useSessionState(`_pn_commission_${id}`, 0);
	const [selectedAuction, setSelectedAuction] = useSessionState(`_pn_selected_auction_${id}`, '');
	const [showFee, setShowFee] = useSessionState(`_pn_show_fee_${id}`, false);
	const [noCommission, setNoCommission] = useSessionState(`_pn_no_commission_${id}`, 'no');

	const [items, setItems] = useState([])
	const [seller, setSeller] = useState(null)

	const load = async () => {
		const data = await PNTrading.get(`/v2/summaries/project-summary/${id}?t=t${selectedAuction != null ? `&auction=${selectedAuction}` : ''}`)
		setItems(data.items);
		setSeller(data.seller);
	};

	useEffect(() => {
		load();
	}, [id, selectedAuction]);

	if (!seller) {
		return (
			<div style={{ width: '100%', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);
	}

	const net_total = items.reduce((acc, item) => acc + item.net_amount, 0);
	const vat_total = items.reduce((acc, item) => acc + item.vat_amount, 0);
	const sum = net_total + vat_total;

	const commissionAmount = noCommission === 'yes' ? 0 : caluclateCommissionAmount(items.map(item => ({
		...item.object ?? {},
		customAmount: item.net_amount,
	})), commission / 100);

	const commissionTax = commissionAmount * 0.25;
	const commissionTotal = Math.round((commissionAmount + commissionTax) * 100) / 100;

	const serviceFee = calculateServiceFeeFromInvoiceItems(items);
	const serviceFeeTax = serviceFee * 0.3;
	const serviceFeeTotal = Math.floor(serviceFee + serviceFeeTax);

	const toBeReceived = Math.round((sum - commissionTotal) * 100) / 100;

	return (
		<Style>
			<Card size="medium" className="hide-print">
				<h2>Projektsummering</h2>
				<h5>(denna ruta visas inte vid utskrift)</h5>
				<div className="spacer s"></div>
				<p>Denna sida kan skrivas ut direkt i webbläsaren, antingen som en PDF eller via skrivare. Provisionen för projektet kan justeras här innan utskrift, eventuella kostnader på objekten kommer räknas av EFTER provision.</p>
				<div className="spacer s"></div>

				<div className="row">
					<div className="column">
						<label>Välj en auktion</label>
						<div className="spacer xs"></div>
						<AuctionSelect
							style={{ minWidth: '20rem' }}
							defaultValueID={selectedAuction}
							onChange={(e) => setSelectedAuction(e.value)}
						/>
					</div>

					<div className="spacer ws"></div>

					<div className="column">
						<label>Inkludera serviceavgift</label>
						<div className="spacer xs"></div>
						<select onChange={(e) => setShowFee(e.target.value)} value={showFee}>
							<option value="false">Nej</option>
							<option value="true">Ja</option>
						</select>
					</div>
				</div>

				<div className="spacer s"></div>

				<div className="row" style={{ alignItems: 'flex-end' }}>
					<div className="column">
						<label htmlFor="commission">Provision (%)</label>
						<div className="spacer xs"></div>
						<input style={{ width: 120 }} type="number" value={commission} min={0} max={100} onChange={(e) => setCommission(e.target.value ?? 0)} name="commission" placeholder="Provision (%)" />
					</div>
					<div className="spacer ws"></div>
					<div className="column">
						<label htmlFor="commission">Utan provision</label>
						<div className="spacer xs"></div>
						<select value={noCommission} onChange={e => setNoCommission(e.target.value)}>
							<option value="no">Nej</option>
							<option value="yes">Ja</option>
						</select>
					</div>
					<div className="spacer ws"></div>
					<button onClick={() => window.print()} disabled={false}>Skriv ut</button>
				</div>
			</Card>

			<Card size="full" className="page-card">
				{seller ? (
					<>
						<div className="header">
							<div className="logo">
								<img src="/ra-logga-invoice.png" alt="" />
							</div>

							<div className="document-info">
								<p>
									<b>{seller.name}</b> ({seller.org_number})
								</p>
								<p>
									<b>Referens:</b> {seller.trustee?.name}
								</p>
								<p>{seller.trustee?.email}</p>
							</div>
						</div>

						<div className="spacer l"></div>

						<div className="summary-text">
							<p className="small-title metropolis">sålda objekt</p>
							<div className="spacer xs"></div>
							<h1 className="title">Sammanställning</h1>
							<div className="spacer s"></div>
							<p className="description">Tack för du väljer att sälja med oss på Riksauktioner. Här kommer din auktionssammanställning med information om dina sålda objekt och utbetalning. Har du några frågor är du varmt välkommen att kontakta oss på: ekonomi@riksauktioner.se</p>
						</div>

						<div className="spacer l"></div>

						<div className="summary-row">
							<div className="column summary">
								<p className="small-title">summa total försäljning</p>
								<div className="spacer xs"></div>
								<div className="box">
									<p>
										Summa: <b>{formatPrice(net_total)}</b>
									</p>
									<p>
										Moms: <b>{formatPrice(vat_total)}</b>
									</p>
									<p>
										Totalt ink. moms: <b>{formatPrice(sum)}</b>
									</p>
								</div>
							</div>

							<div className="column commission">
								<p className="small-title">provision</p>
								<div className="spacer xs"></div>
								<div className="box">
									<p>
										Provision: <b>{formatPrice(commissionAmount.toFixed(2))}</b> ({commission}%)
									</p>
									<p>
										Moms: <b>{formatPrice(commissionTax.toFixed(2))}</b>
									</p>
									<p>
										Totalt ink. moms: <b>{formatPrice(commissionTotal)}</b>
									</p>
								</div>
							</div>

							{showFee === 'true' && (
								<div className="column fee">
									<p className="small-title">serviceavgift</p>
									<div className="spacer xs"></div>
									<div className="box">
										<p>
											Serviceavgift: <b>{formatPrice(serviceFee.toFixed(2))}</b>
										</p>
										<p>
											Moms: <b>{formatPrice(serviceFeeTax.toFixed(2))}</b>
										</p>
										<p>
											Totalt ink. moms: <b>{formatPrice(serviceFeeTotal)}</b>
										</p>
									</div>
								</div>
							)}
						</div>

						<div className="spacer m"></div>

						<div className="to-be-recived-box">
							<p className="small-title">insättning</p>
							<div className="spacer s"></div>
							<p className="italics">
								Insättning sker <b>14</b> bankdagar efter avslutad auktion.
							</p>
							<div className="spacer s"></div>
							<p>
								Summa: <b>{formatPrice(toBeReceived)}</b>
							</p>
							{seller.bank_account_number && (
								<p>
									Insättning sker till konto: <b>{seller.bank_account_number}</b>
								</p>
							)}
						</div>

						<div className="spacer m"></div>

						<p className="small-title">dina sålda objekt</p>
						<div className="spacer xs"></div>
						<table className="objects-table" cellSpacing={0}>
							<thead>
								<tr>
									<th></th>
									<th></th>
									<th>Betaldatum</th>
									<th>Vinnande bud</th>
								</tr>
							</thead>

							<tfoot>
								<tr>
									<td style={{ height: '2rem' }}></td>
								</tr>
							</tfoot>
							<tbody>
								{items.map((item) => (
									<tr key={item.id}>
										<td className="image">
											<img src={item.thumbnail} alt="" />
										</td>
										<td className="title">
											<div className="id">
												ID: {item.readable_id}
											</div>
											<div className="title">
												<b>{item.name}</b>
											</div>
										</td>
										<td className="ended">
											<b>{item.mainPayment ? new Date(item.mainPayment.payment_date).getDateString() : '-'}</b>
										</td>
										<td className="bid">
											<b>{formatPrice(item.net_amount)}</b>
										</td>
									</tr>
								))}
							</tbody>
						</table>

						<div className="footer">
							<div className="meta-row">
								<p>Riksauktioner AB</p>
								<p>Org. 556236-6954</p>
								<p>Tel: 08 446 17 12</p>
								<p>BG: 5849-4808</p>
								<p>www.riksauktioner.se</p>
							</div>
						</div>
					</>
				) : (
					<>
						<div style={{ width: '100%', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<div className="loader"></div>
						</div>
					</>
				)}
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	p {
		font-size: 12px;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.logo {
			img {
				width: 6cm;
			}
		}

		.document-info {
			text-align: right;
		}
	}

	.small-title {
		text-transform: uppercase;
		font-size: 10px;
	}

	.summary-text {
		.title {
			font-size: 25.5px;
			font-weight: normal;
		}

		.description {
			font-size: 15px;
			width: 82%;
			line-height: 1.75em;
		}
	}

	.summary-row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2rem;

		.column {
			.box {
				border: solid 1px #f4f4f4;
				border-radius: 10px;
				padding: 0.75rem;

				p {
					&:not(:last-child) {
						margin-bottom: 0.3rem;
					}
				}
			}
		}
	}

	.to-be-recived-box {
		border: solid 1px #f4f4f4;
		border-radius: 10px;
		padding: 0.75rem;
		background-color: #f4f4f4;

		.italics {
			font-style: italic;
		}
	}

	.objects-table {
		width: 100%;

		th {
			padding-top: 1rem;
			font-size: 12px;
			text-align: right;
			font-weight: 200;
			border-bottom: 1px solid #f4f4f4;
		}

		tbody {
			tr {
				td {
					font-size: 15px;
					vertical-align: bottom;
					padding-top: 0.7rem;
					padding-bottom: 0.7rem;
					/* border-top: 1px solid #F4F4F4; */
					border-bottom: 1px solid #f4f4f4;

					&.image {
						padding-bottom: 0.4rem;
						img {
							width: 38px;
							height: 38px;
							border-radius: 5px;
						}
					}

					&.title {
						padding-left: 1rem;

						.id {
							font-size: 10px;
							font-weight: normal;
						}

						.title {
							width: 25rem;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							text-transform: capitalize;
						}
					}

					&.ended {
						text-align: right;
					}

					&.bid {
						text-align: right;
					}

					&.exclude-checkbox {
						& > label {
							margin-left: 1rem;
							margin-bottom: 1rem;
						}
					}
				}

				&.excluded {
					opacity: 0.5;
				}
			}
		}
	}

	.footer {
		padding-top: 1rem;

		.meta-row {
			display: flex;
			align-items: center;
			justify-content: center;

			p {
				color: #555555;
				height: 1em;
				line-height: 1em;

				&:not(:last-child) {
					margin-right: 0.5rem;
					padding-right: 0.5rem;
					border-right: solid 1px currentColor;
				}
			}
		}
	}

	@media screen {
		.page-card {
			width: 22cm;
		}

		.mobile & {
			.hide-print {
				width: 100%;
				min-width: 0;

				.row {
					flex-direction: column;
					align-items: stretch !important;

					& > * {
						margin-bottom: 0.5rem;
					}
				}
			}

			.page-card {
				display: none;
			}
		}
	}

	@media print {
		.footer {
			position: fixed;
			bottom: 1rem;
			width: 100%;
		}
	}
`;
