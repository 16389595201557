import InvoiceStatus from "src/components/invoices/InvoiceStatus";
import API from "src/utils/api/API";
import { InvoiceItem } from "src/utils/api/Invoices";

export default class Accounting {

    static async getAccountableSellers(): Promise<AccountableSellersSeller[]> {
        const resp = await API.get(`/v2/accounting`);
        if (!resp.success as any) throw new Error(resp.message);
        return resp.sellers as AccountableSellersSeller[];
    }

    static async getAccountableAuctions(args?: GetAccountableAuctionsArgs): Promise<AccountableAuctionsAuction[]> {

        let query: [string, string][] = [];
        if (args?.hasAccountable) query.push(['hasAccountable', 'true']);
        if (args?.limit) query.push(['limit', args.limit.toString()]);

        const resp = await API.get(`/v2/accounting/auctions${query.length > 0 ? '?' + new URLSearchParams(query).toString() : ''}`);
        if (!resp.success as any) throw new Error(resp.message);
        return resp.auctions as AccountableAuctionsAuction[];
    }

    static async getAccountableAuction(id: number): Promise<AccountableAuction> {
        const resp = await API.get(`/v2/accounting/auctions/${id}`);
        if (!resp.success as any) throw new Error(resp.message);
        return resp.auction as AccountableAuction;
    }

    static async discloseItems(auction_id: number, commissionPercentage: number, item_ids: number[]): Promise<void> {
        const resp = await API.post(`/v2/accounting/auctions/${auction_id}`, {
            commissionPercentage,
            invoiceItemIds: item_ids
        });
        if (!resp.success as any) throw new Error(resp.message);
    }


}


export type AccountableSellersSeller = {
    id: number;
    name: string;
    trustee_id: number;
    trustee_name: string;
    customer_id: number;
    customer_name: string;
    num_items: number;
    total_net_amount: number;
    total_vat_amount: number;
}

export type AccountableAuctionsAuction = {
    id: number;
    name: string;
    ending: string;
    num_objects: number;
    won_items: number;
    accountable_objects: number;
    total_net_amount: number;
    total_vat_amount: number;
}

export type GetAccountableAuctionsArgs = {
    hasAccountable?: boolean;
    limit?: number;
}

export type AccountableAuction = {
    id: number;
    name: string;
    ending: string;
    items: AccountableAuctionItem[];
}

export type AccountableAuctionItem = InvoiceItem & {
    invoice_status: typeof InvoiceStatus[keyof typeof InvoiceStatus],
    invoice_paid_date: string | null;
    object: null | {
        position: number;
        category: number | null;
        ending: string;
        leading_user: number;
        leading_username: string;
        leading_bid: number;
        leading_time_placed: number;
        thumbnail_url: null | string;
    },
    seller: null | {
        name: string;
        org_number: string;
        vat_number: string;
        bank_account_number: string;
        notes: string;
        trustee: null | {
            id: number;
            name: string;
            address: string;
            zip: string;
            city: string;
            phone: string;
            mobile_phone: string;
            email: string;
            added: string;
            customer: number
        }
        slug: string;
    }
}