import React, { useEffect, useState } from 'react';
import Table from '../components/Table';
import Page from '../layout/Page';
import Card from '../layout/Card';
import Icon from '../elements/Icon';
import usePopup from '../utils/hooks/usePopup';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import DeleteAuction from '../layout/popups/DeleteAuction';
import PNTrading from 'src/pn-trading-sdk';
import useSessionState from '../utils/hooks/useSessionState';
import useAccountableAuctions from 'src/utils/api/accounting/hooks/useAccountableAuctions';
import { useUser } from 'src/pn-trading-sdk/utils/UserToken';

export default function PageAuctions() {
	const limit = 10;

	const history = useHistory();
	const [open] = usePopup();

	const [auctions, setAuctions] = useState();
	const [status, setStatus] = useSessionState('_pn_auction_status', 'active');
	const [searchString, setSearchString] = useSessionState('_pn_auction_search', '');

    const { auctions: accountableAuctions } = useAccountableAuctions({
		hasAccountable: true,
		limit: -1
	});
	
	const user = useUser();

	const isLoading = auctions == undefined;

	const load = async () => {
		let auctionsResponse = await PNTrading.get(`/auctions?noCache=true${status != 'all' ? `&status=${status}` : ''}`);

		setAuctions(
			auctionsResponse
				.map((a) => ({
					...a,
					status: (a.status == 'draft' || a.status == 'empty') && new Date(a.added).isAfter(new Date().addDays(-1)) ? 'new' : a.status,
				}))
				.filter((a) => a.name.toLowerCase().includes(searchString.toLowerCase()))
				.sort((a, b) => {
					let aTime = new Date(a.ending).getTime();
					let bTime = new Date(b.ending).getTime();

					if (aTime == 0) return -1;

					if (aTime == bTime) return 0;
					if (aTime > bTime) return -1;
					if (aTime < bTime) return 1;
				})
		);
	};

	useEffect(() => load(), [status, searchString]);

	if (isLoading)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<Style>
			<Card size="full">
				<div className="header">
					<h1>Auktioner</h1>
					<div className="spacer auto"></div>

					<select value={status} onChange={(e) => setStatus(e.target.value)}>
						<option value="all">Välj status...</option>
						<option value="active">Aktiva auktioner</option>
						<option value="empty">Tomma auktioner</option>
						<option value="ended">Avslutade auktioner</option>
					</select>

					<div className="spacer ws"></div>

					<input placeholder="Sök auktion..." value={searchString} onChange={(e) => setSearchString(e.target.value)} />
				</div>

				<div className="spacer m"></div>

				<Table
					data={auctions}
					pages={Math.ceil(auctions?.length / limit)}
					tableID={'main_auctions_table'}
					structure={[
						{
							heading: 'Namn',
							component: (row) => (
								<span title={JSON.stringify(row, null, 4)}>
									{row.id} - {row.name}
								</span>
							),
						},
						{
							heading: 'Status',
							component: (row) =>
								({
									draft: <div className="badge empty">Utkast</div>,
									ended: <div className="badge ended">Avslutad</div>,
									active: <div className="badge active">Aktiv</div>,
									new: <div className="badge active">Ny</div>,
									empty: <div className="badge empty">Tom</div>,
								}[row.status]),
						},
						(user?.permissions.includes('employee_admin')) && {
							heading: 'Redovisat',
							component: (row) => {
								const accountableAuction = accountableAuctions.find((a) => a.id === row.id);

								if (!accountableAuction) return '';

								const totalItems = accountableAuction.won_items;
								const accountableItems = accountableAuction.accountable_objects;

								if (accountableItems === 0) return <div className="badge active">Inget att redovisa</div>;

								if (accountableItems <= totalItems) return <div className="badge yellow" onClick={() => {
									history.push(`/redovisning/auction/${row.id}`);
								}}>{accountableItems} att redovisa</div>;
							},
						},
						{
							heading: 'Tillagd',
							component: (row) => new Date(row.added).toJSON().replace('T', ' ').substr(0, 10),
						},
						{
							heading: 'Avslutas',
							component: (row) => (row.ending ? new Date(row.ending).toLocaleTimeString('sv-SE', {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric',
								hour: '2-digit',
								minute: '2-digit',
							}) : '-'),
						},
						{
							heading: '',
							className: 'row-icons',
							component: (row) => {
								return (
									<div>
										{row.status == 'ended' && (
											<>
												<Icon onClick={() => history.push(`/pay-auction/${row.id}`)}>payment</Icon>
											</>
										)}
										<Icon onClick={() => history.push(`/auctions/${row.id}`)}>edit</Icon>
										<Icon onClick={() => open(<DeleteAuction id={row.id} />)}>delete</Icon>
									</div>
								);
							},
						},
					]}
				/>
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	.header {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.badge {
		font-size: 0.85rem;
		padding: 0.3em 1em;
		border-radius: 10em;

		&.ended {
			background: #f5614f;
			color: white;
		}

		&.empty {
			background: #d9d9d9;
			color: white;
		}

		&.active {
			background: #21ab21;
			color: white;
		}
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;
