import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import styled from 'styled-components';
import PNTrading from 'src/pn-trading-sdk';
import { formatPrice } from '../../utils/Util';
import useSessionState from '../../utils/hooks/useSessionState';

export function getUsernameByObject(object) {
	return object.ending_data ? (object.ending_data.lastBid.username ?? object.leading_username) : undefined
}

// Sorts the objects by username.
export const sortByWinningUser = (a, b) => {
	return ('' + (a.winning_username ? a.winning_username : 'öööööööööööööööööööö')).localeCompare(b.winning_username ? b.winning_username : 'ö');
};

export default function PageTickOff(props) {
	let id = props.match.params.id;

	const [sortBy, setSortBy] = useSessionState('tickoff_sort', 'user');

	const [objects, setObjects] = useState();
	const [auction, setAuction] = useState();

	useEffect(() => {
		function fetchObjects() {
			PNTrading.get(`/v2/summaries/tick-off/${id}`).then((data) => {
				setAuction(data.auction);
				setObjects(data.objects);
			});
		}

		fetchObjects();
		let intervalID = setInterval(fetchObjects, 5000);
		return () => clearInterval(intervalID);
	}, [id]);

	const updatePickStatus = (id, status) => {
		const object = objects.find((object) => object.id === id);

		if (object.invoice_item) {
			if (status === true) {
				PNTrading.post(`/v2/invoices/items/${object.invoice_item.id}/mark-as-delivered`);
			} else {
				PNTrading.post(`/v2/invoices/items/${object.invoice_item.id}/mark-as-undelivered`);
			}
		}
		
		PNTrading.put(`/objects/${id}`, { tickoff: status }, true);
		
		setObjects((objects) => {
			let newobjects = objects.map((object) => {
				if (object.id == id) {
					let tmp = { ...object };
					tmp.tickoff = status;
					return tmp;
				} else {
					return object;
				}
			});
			return newobjects;
		});
	};

	function sort(a, b) {
		if (sortBy == 'object') {
			return a.position - b.position;
		}

		return sortByWinningUser(a, b)
	}

	if (!objects || !auction)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<Style>
			<Card size="full">
				<div className="title-row flex row center space-between">
				<h2>
					Avprickning för <strong>{auction.name}</strong>
				</h2>
				<select
					name="sort-by"
					value={sortBy}
					onChange={(e) => setSortBy(e.target.value)}
				>
					<option value="user">Sortera efter användare</option>
					<option value="object">Sortera efter objekt ID</option>
				</select>
				</div>
				<div className="spacer m"></div>

				<div className="objects">
					{objects.sort(sort).map((object) => {
						return (
							<div key={object.id} className={`object ${object.tickoff === true ? 'picked' : ''} ${object.winning_bid === null ? 'nobids' : ''}`}>
								{object.winning_bid !== null &&
									{
										unpaid: <div className="badge red">EJ BETALD</div>,
										paid: <div className="badge green">BETALD</div>,
										draft: <div className="badge gray">UTKAST</div>,
										undefined: <div className="badge red">SAKNAR BETALNING</div>,
									}[object.invoice_item?.status]}
								<img src={object.thumbnail_url} alt="" />
								<div className="content">
									<h2># {object.position}</h2>
									<h4>{object.title}</h4>
									<p>
										<b>Köpare:</b> {object.winning_username}
									</p>
									<p>
										<b>Bud:</b> {object.winning_bid ? formatPrice(object.winning_bid) : ' - '}
									</p>

									<select name="tick-off-status" value={object.tickoff === true ? 'yes' : 'no'} onChange={(e) => updatePickStatus(object.id, e.target.value === 'yes' ? true : false)}>
										<option value="no">Ej hämtad</option>
										<option value="yes">Hämtad</option>
									</select>
								</div>
							</div>
						);
					})}
				</div>
			</Card>
		</Style>
	);
}

const Style = styled(Page)`

	.title-row {
		.mobile & {
			flex-direction: column;
			align-items: stretch;
			gap: 1rem;

			h2 {
				font-size: 1rem;
			}
		}
	}

	.objects {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 1rem;

		.object {
			padding: 1rem;
			border-radius: 1rem;
			position: relative;

			&.nobids {
				opacity: 0.1;
				pointer-events: none;
			}

			&.picked {
				border-color: #21ab21;
				background-color: #d7ecd7;
			}

			.badge {
				position: absolute;
				top: 1.5rem;
				left: 1.5rem;
			}

			img {
				width: 100%;
				height: auto;
				object-fit: cover;
				border-radius: 1rem;
			}

			.content {
				padding-top: 1rem;

				h4 {
					margin-bottom: 0.5rem;
				}

				select {
					margin-top: 0.5rem;
					width: 100%;
				}
			}
		}
	}

	.tablet & {
		.objects {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.mobile & {
		.objects {
			grid-template-columns: repeat(2, 1fr);
			gap: 0.5rem;

			.object {
				padding: 0.5rem;
				border-radius: 0.5rem;

				.badge {
					top: 0.75rem;
					left: 0.75rem;

					font-size: 0.75rem;

					padding: 0.25rem 0.5rem;
					border-radius: 0.5rem;
				}

				img {
					border-radius: 0.5rem;
				}

				.content {

					h2 {
						font-size: 1rem;
					}

					h4 {
						margin-bottom: 0.25rem;
						font-size: 0.75rem;
					}

					p {
						font-size: 0.75rem;
					}

					select {
						padding: 0.25rem 0.5rem;
					}
				}
			}
		}
	}
`;
