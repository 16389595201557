import React, { useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import Icon from '../../elements/Icon';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Invoices from '../../utils/api/Invoices';
import { formatPrice } from '../../utils/Util';
import AuctionSelect from '../../components/auction/AuctionSelect';
import useStoredState from '../../utils/hooks/useStoredState';
import DeletePayment from './components/DeletePayment';
import usePopup from '../../utils/hooks/usePopup';
import Button from '../../elements/Button';

export default function PageInvoices() {
	const limit = 10;

	const history = useHistory();
	const lastFetch = useRef(null)

	const [invoices, setInvoices] = useState();

	const [open] = usePopup()

	const [filters, setFilters] = useStoredState('invoices_filters', {});

	const isLoading = invoices == undefined;

	const load = async ({ page } = {}) => {
		const cacheKey = (JSON.stringify({ filters, page }));

		if (lastFetch.current && lastFetch.current.key === cacheKey && Date.now() - lastFetch.current.time < 2000) {
			return invoices.data;
		}

		let _invoices = await Invoices.list({
			limit,
			page: page ?? 0,
			embed: true,
			...filters,
		});

		lastFetch.current = {
			time: Date.now(),
			key: cacheKey
		}

		setInvoices(_invoices);

		return _invoices.data;
	};

	useEffect(() => {
		load();
	}, [filters]);

	if (isLoading)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<Style>
			<Card size="full">
				<div className="header">
					<h1>Fakturor</h1>
					<div className="spacer auto"></div>
					<Button onClick={() => Invoices.create().then(load)}>Skapa faktura</Button>
				</div>

				<div className="spacer m"></div>

				<div className="filters">
					<div className="filter status">
						<label>Status</label>
						<select value={filters.status || ''} onChange={(e) => setFilters((f) => ({ ...f, status: e.target.value }))}>
							<option value="">Alla</option>
							<option value="paid">Betalad</option>
							<option value="processing">Obetald</option>
							<option value="canceled">Avbryten</option>
						</select>
					</div>

					<div className="filter type">
						<label>Betalmetod</label>
						<select value={filters.payment_method || ''} onChange={(e) => setFilters((f) => ({ ...f, payment_method: e.target.value }))}>
							<option value="">Alla</option>
							<option value="onsite_card">På plats - Kort</option>
							<option value="onsite_cash">På plats - Kontant</option>
							<option value="onsite_swish">På plats - Swish</option>
							<option value="invoice_bank">Faktura - Inbetalning Bank</option>
							<option value="invoice_swish">Faktura - Swish</option>
						</select>
					</div>

					<div className="filter created">
						<label>Datum skapad</label>
						<div className="inputs row center">
							Från
							<div className="spacer wxs"></div>
							<input type="date" value={filters.created_after || ''} onChange={(e) => setFilters((f) => ({ ...f, created_after: e.target.value }))} />
							<div className="sep">till</div>
							<input type="date" value={filters.created_before || ''} onChange={(e) => setFilters((f) => ({ ...f, created_before: e.target.value }))} />
						</div>
					</div>

					<div className="filter paid">
						<label>Datum betalad</label>
						<div className="inputs row center">
							Från
							<div className="spacer wxs"></div>
							<input type="date" value={filters.paid_after || ''} onChange={(e) => setFilters((f) => ({ ...f, paid_after: e.target.value }))} />
							<div className="sep">till</div>
							<input type="date" value={filters.paid_before || ''} onChange={(e) => setFilters((f) => ({ ...f, paid_before: e.target.value }))} />
						</div>
					</div>

					<div className="filter auction">
						<label>Auktion</label>
						<AuctionSelect
							defaultValueID={filters.auction}
							onChange={(e) => {
								setFilters((f) => ({ ...f, auction: e.value }));
							}}
						/>
					</div>
				</div>

				<button disabled={Object.keys(filters).length == 0} onClick={() => setFilters({})}>
					Rensa filter
				</button>

				<div className="spacer m"></div>

				<Table
					pages={invoices ? invoices.pages : 1}
					limit={limit}
					tableID={'invoices'}
					// The table requests new data when the user interacts with it.
					load={load}
					data={invoices.data ?? []}
					structure={[
						{
							heading: 'ID',
							key: 'id',
						},
						{
							heading: 'Användare',
							key: 'user',
							component: (row) => [row.buyer_first_name, row.buyer_last_name].filter((n) => n).join(' ') || '-',
						},
						{
							heading: 'Betalningsmetod',
							key: 'type',
							component: (row) => {
								return '-'
							},
						},
						{
							heading: 'Status',
							key: 'status',
							component: (row) => {
								return {
									unpaid: <div className="badge red">OBETALD</div>,
									draft: <div className="badge gray">UTKAST</div>,
									paid: <div className="badge green">BETALD</div>,
								}[row.status ?? 'unpaid'];
							},
						},
						{
							heading: 'Skapad',
							key: 'time_created',
							component: (row) => {
								return new Date(row.created_at).toLocaleDateString('sv-SE', {
									year: 'numeric',
									month: 'numeric',
									day: 'numeric',
									hour: 'numeric',
									minute: 'numeric',
									second: 'numeric',
								});
							},
						},
						{
							heading: 'Betalad',
							key: 'payed_date',
							component: (row) => {
								return row.paid_date ? new Date(row.paid_date).toLocaleDateString('sv-SE', {
									year: 'numeric',
									month: 'numeric',
									day: 'numeric',
									hour: 'numeric',
									minute: 'numeric',
									second: 'numeric',
								}) : '-';
							},
						},
						{
							heading: 'Totalt',
							key: 'total',
							component: (row) => {
								const total = row.total_net_amount + row.total_vat_amount;
								return formatPrice(total);
							},
						},
						{
							heading: '',
							className: 'row-icons',
							component: (row) => {
								return (
									<div>
										<Icon onClick={() => history.push(`/invoices/${row.id}`)}>edit</Icon>
										{(row.status === 'unpaid' || row.status === 'draft') && <Icon onClick={() => open(<DeletePayment payment={row} onDeleted={() => load()} />)}>delete</Icon>}
									</div>
								);
							},
						},
					]}
				/>
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	.key-values {
		h1 {
			font-size: 2.5em;
		}
	}

	.filters {
		/* padding: 2rem; */
		border-radius: 0.5rem;
		display: flex;
		flex-wrap: wrap;

		.filter {
			background: #f8f8f8;
			display: flex;
			flex-direction: column;
			margin-right: 1rem;
			margin-bottom: 1rem;
			border: #ccc solid 1px;
			padding: 1rem;
			border-radius: 0.5rem;

			label {
				margin-bottom: 0.35rem;
			}

			.sep {
				margin-left: 0.5rem;
				margin-right: 0.5rem;
			}
		}

		.mobile & {
			flex-direction: column;

			.row {
				flex-wrap: wrap;

				& > * {
					margin-bottom: 0.5rem;
				}
			}
		}
	}

	.header {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		form {
			display: flex;
			align-items: center;
		}
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;
