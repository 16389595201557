import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useClosePopup } from 'src/utils/hooks/usePopup';
import Popup from 'src/layout/Popup';
import Button from 'src/elements/Button';
import { CustomerInvoice } from 'src/utils/api/customer-invoices/CustomerInvoices';
import API from 'src/utils/api/API';

async function getBLCustomer(invoice: CustomerInvoice) {
    const resp = await API.get(`/v2/customer-invoices/${invoice.id}/bl-customer`);
    if (!resp.success as any) throw new Error(resp.message);
    return resp.data;
}

async function sendToBL(invoice: CustomerInvoice) {
    const resp = await API.post(`/v2/customer-invoices/${invoice.id}/submit-to-bl`);
    if (!resp.success as any) throw new Error(resp.message);
    return resp.invoice;
}

export default function SendToBLPopup({ invoice, setCustomerInvoice }: { invoice: CustomerInvoice, setCustomerInvoice?: any }) {
	const close = useClosePopup();

    const [blCustomer, setBlCustomer] = useState<any>(null);


    useEffect(() => {
        getBLCustomer(invoice).then(setBlCustomer).catch(e => {
            console.error(e);
            setBlCustomer(false);
        });
    }, [invoice.id])
    

	const markAsPayed = async () => {

		const resp = await sendToBL(invoice);
        if (setCustomerInvoice) setCustomerInvoice(resp);

		close();
	};

	return (
		<Popup close={close} width="40em" maxWidth="30em">
			<Style>
				<h2 className="title">Skicka till Björn Lunden</h2>
				<div className="spacer s"></div>

                {blCustomer === null && <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    paddingTop: 30,
                    paddingBottom: 30,
                }}>
                    <div className="loader"></div>
                </div>}

                {blCustomer === false && <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    border: '1px solid #f00',
                    borderRadius: 5,
                    backgroundColor: '#fdd',
                    padding: 20,
                    textAlign: 'center',
                }}>
                    <p>Kunde inte hitta kund hos Björn Lunden. Se till att korrekt kundnummer är inlagt i CMS.</p>
                </div>}

                {blCustomer && <div>
                    <p>
                        Fakturaundelaget kommer att skickas till Björn Lunden för kund <strong>{blCustomer.name}</strong> med kundnummer <strong>{blCustomer.companyId}</strong>, i Björn Lunden så kommer fakturan hamna som ett utkast för att senare kunna skickas till kund.
                    </p>
                </div>}

				<div className="spacer s"></div>

				<div className="buttons">
					<Button type="secondary" onClick={close}>
						Nej, avbryt
					</Button>
					<Button onClick={markAsPayed} disabled={!blCustomer}>Skicka</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
