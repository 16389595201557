import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Card from '../../layout/Card';
import { UseInvoiceInvoice } from 'src/utils/api/Invoices';
import Icon from 'src/elements/Icon';
import SetInvoiceUser from 'src/components/invoices/SetInvoiceUser';

export default function BuyerCard({ invoice }: { invoice: UseInvoiceInvoice }) {

	const form = useRef<HTMLFormElement>(null);
	const [state, setState] = useState<'editing' | 'loading' | 'viewing'>('viewing');

	function onSubmit(e: React.FormEvent) {
		e.preventDefault();
		setState('loading');

		const data = new FormData(form.current as any);

		invoice.update({
			buyer_first_name: data.get('buyer_first_name') as string,
			buyer_last_name: data.get('buyer_last_name') as string,
			buyer_address: data.get('buyer_address') as string,
			buyer_zip_code: data.get('buyer_zip_code') as string,
			buyer_city: data.get('buyer_city') as string,
			buyer_phone: data.get('buyer_phone') as string,
			buyer_company_name: data.get('buyer_company_name') as string,
			buyer_country: data.get('buyer_country') as string,
		}).then(() => {
			setState('viewing');
		}).catch((e) => {
			setState('editing');
			alert(e.message);
		});
		
	}

	function toggleEdit() {
		if (state === 'loading') return;
		if (state === 'editing') {
			setState('viewing');
		} else {
			setState('editing');
		}
	}

	const edit = state === 'editing';

	return (
		<Style size="medium">
			<div className="row center">
				<div className="h3">Köpare</div>
				<div className="spacer auto"></div>
				<Icon style={{fontSize: 20}} onClick={toggleEdit}>{{
					'viewing': 'edit',
					'editing': 'close',
					'loading': 'autorenew'
				}[state]}</Icon>
			</div>
			<div className="spacer s"></div>

			{edit && (
				<>
				<SetInvoiceUser invoice={invoice} />
				<div className="divider l"></div>
				</>
			)}

			<form ref={form} onSubmit={onSubmit}>

				<div className="input-column">
					<label>Personnummer / Org. Nr</label>
					<div className="spacer xs"></div>
					<input disabled={!edit} type="text" name="buyer_org_nr" defaultValue={invoice.buyer_org_nr} placeholder={'XXXXXX-XXXX'} />
				</div>

				<div className="spacer s"></div>

				<div className="input-column">
					<label>Namn *</label>
					<div className="spacer xs"></div>
					<div className="row">
						<input required disabled={!edit} type="text" name="buyer_first_name" defaultValue={invoice.buyer_first_name ?? ''} />
						<div className="spacer wxs"></div>
						<input required disabled={!edit} type="text" name="buyer_last_name" defaultValue={invoice.buyer_last_name ?? ''} />
					</div>
				</div>

				<div className="spacer s"></div>

				<div className="input-column">
					<label>Adress *</label>
					<div className="spacer xs"></div>
					<input required disabled={!edit} type="text" placeholder="Adress" name="buyer_address" defaultValue={invoice.buyer_address ?? ''} />

					<div className="spacer xs"></div>
					<div className="row">
						<input required disabled={!edit} type="text" placeholder="Postnummer" name="buyer_zip_code" defaultValue={invoice.buyer_zip_code ?? ''} />
						<div className="spacer wxs"></div>
						<input required disabled={!edit} type="text" placeholder="Stad" name="buyer_city" defaultValue={invoice.buyer_city ?? ''} />
					</div>

					<div className="spacer xs"></div>
					<input required disabled={!edit} type="text" placeholder="Land" name="buyer_country" defaultValue={invoice.buyer_country || 'Sverige'} />
				</div>

				<div className="spacer s"></div>

				<div className="input-column">
					<label>Telefonnummer</label>
					<div className="spacer xs"></div>
					<input disabled={!edit} type="text" name="buyer_phone" defaultValue={invoice.buyer_phone ?? ''} />
				</div>

				<div className="spacer m"></div>

				<div className="input-column">
					<label>Företagsnamn</label>
					<div className="spacer xs"></div>
					<input disabled={!edit} type="text" name="buyer_company_name" defaultValue={invoice.buyer_company_name ?? ''} />
				</div>

				{edit && <>
					<div className="spacer m"></div>
					<button type="submit">Spara</button>
				</>}
			</form>
		</Style>
	);
}

const Style = styled(Card)``;
