import { useEffect, useState } from 'react';
import Accounting, { AccountableAuction } from '../Accounting';


export default function useAccountableAuction(id: undefined | null | number) {
    const [auction, setAuction] = useState<AccountableAuction | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    function load() {
        setLoading(true);
        setError(null);
        if (id) {
            Accounting.getAccountableAuction(id)
                .then(setAuction)
                .catch(e => setError(e.message))
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        load();
    }, [id]);

    return { auction, loading, error, load };
}