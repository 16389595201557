import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import { searchString } from '../utils/Util';
import { useRef } from 'react';

/**
 * 
 * @param {Object} props
 * @param {Array} props.children
 * @param {number=} props.defaultValue
 * @param {function=} props.onChange
 * @param {function=} props.onSelect
 * @param {boolean=} props.disabled
 * @param {boolean=} props.allowSelect
 * @param {boolean=} props.ellipsis
 * @param {Object=} props.rest
 */
export default function Select({ children, ellipsis = false, defaultValue = undefined, onChange = (e) => {}, onSelect = () => {}, disabled = false, allowSelect = true, ...rest }) {
	// Store the search string.
	const [search, setSearch] = useState('');
	const [open, setOpen] = useState(false);
	const dropdown = useRef();
	const [selected, setSelectedState] = useState(defaultValue);
	const [marked, setMarked] = useState(0);

	const setSelected = (selected) => {
		if (disabled) return;

		onSelect({
			index: selected,
			component: selected != undefined ? children[selected] : undefined,
			value: selected != undefined ? children[selected].props.value : undefined,
			text: selected != undefined ? children[selected].props.children : undefined,
		});

		if (!allowSelect) return;

		setSelectedState(selected);

		if (children)
			onChange({
				index: selected,
				component: selected != undefined ? children[selected] : undefined,
				value: selected != undefined ? children[selected].props.value : undefined,
				text: selected != undefined ? children[selected].props.children : undefined,
			});
	};

	useEffect(() => {
		setSelectedState(defaultValue);
	}, [defaultValue]);

	// Click outside check.
	useEffect(() => {
		function findAncestor(el, sel) {
			while ((el = el.parentElement) && !(el.matches || el.matchesSelector).call(el, sel));
			return el;
		}

		window.addEventListener('click', function (e) {
			let clickedOnDropdown = e.target == dropdown.current || dropdown.current == findAncestor(e.target, '.select');

			if (!clickedOnDropdown) setOpen(false);
		});
	}, []);

	const closeDropdown = () => {
		if (disabled) return;
		setOpen(false);
		setSearch('');
	};

	const openDropdown = () => {
		if (disabled) return;
		setOpen(true);
		if (dropdown.current) {
			dropdown.current.querySelector('input').focus();
		}
	};

	// Make sure the children is an array.
	if (children && !Array.isArray(children)) children = [children];

	// Inject the search string to each child.
	let visible = children
		? children.map((o, i) =>
				o != undefined
					? {
							...o,
							searched: searchString(search, o.props.children),
							props: {
								...o.props,
								search,
								isSelected: selected != undefined && children[selected] && children[selected].key == o.key,
								onClick: () => {
									setSelected(children.indexOf(o));
									closeDropdown();
								},
							},
					  }
					: o
		  )
		: [];

	// Filter the options based on the search.
	visible = visible.filter((c) => {
		return (search == '' || c.searched.hasFound) && ((selected != undefined && children[selected] && children[selected].key != c.key) || selected == undefined);
	});

	// Sort the options based on the search.
	visible = visible.sort((a, b) => {
		return a.searched.found[1] - b.searched.found[1];
	});

	return (
		<SelectStyle ref={dropdown} className={`select ${ellipsis ? 'ellipsis' : ''} ${disabled ? 'disabled' : disabled} ${search != '' ? 'is-searching' : ''} ${open ? 'dropdown-open' : 'dropdown-closed'}`} {...rest}>
			<div
				className="current"
				onClick={() => {
					if (disabled) return;
					if (open) closeDropdown();
					else openDropdown();
				}}
			>
				<h4>{children && selected != undefined ? children[selected] && children[selected].props.children : 'Välj...'}</h4>
				<Icon>expand_more</Icon>
			</div>

			<div className="dropdown">
				<input
					type="text"
					value={search}
					onKeyUp={(e) => {
						if (e.keyCode == 13 && search != '') {
							let o = visible[marked];
							setSelected(children.indexOf(children.find((c) => c.key == o.key)));
							closeDropdown();
						} else if (e.keyCode == 38) {
							if (marked == 0) setMarked(visible.length - 1);
							else setMarked(marked - 1);
							e.preventDefault();
						} else if (e.keyCode == 40) {
							if (marked >= visible.length - 1) setMarked(0);
							else setMarked(marked + 1);
							e.preventDefault();
						} else {
							setMarked(0);
						}
					}}
					onChange={(e) => setSearch(e.target.value)}
				/>
				<div className="spacer s"></div>
				<DropdownOptions className={`options ${search ? 'search' : ''}`} style={{ '--marked': marked + 1 }}>
					{selected != undefined && (
						<Option
							onClick={() => {
								setSelected(undefined);
								closeDropdown();
							}}
						>
							Välj...
						</Option>
					)}
					{visible}
				</DropdownOptions>
			</div>
		</SelectStyle>
	);
}

export function Option({ children, value, search = undefined, onClick = () => {}, marked = false, isSelected = false }) {
	children = searchString(search, children, (s) => `<span>${s}</span>`).string;

	return <div onClick={onClick} value={JSON.stringify(value)} className={`option ${isSelected ? 'is-selected' : ''} ${marked ? 'marked' : ''}`} dangerouslySetInnerHTML={{ __html: children }}></div>;
}

const DropdownOptions = styled.div`
	&:not(:hover) {
		.option:not(.is-selected):nth-child(0) {
			background: #a8a8a8;
			color: white;
		}
	}
`;

const SelectStyle = styled.div`
	position: relative;
	width: 100%;
	min-width: 10rem;

	.current {
		display: flex;
		justify-content: space-between;
		cursor: pointer;
		border: solid 1px rgb(199, 199, 199);
		font-size: 0.85em;
		padding: 0.75em 1em;
		border-radius: 0.35em;
		background: white;
		z-index: 1;

		h4 {
			font-size: 1.1em !important;
		}
	}

	&.disabled {
		opacity: 0.5;

		&,
		* {
			background: #eee;
			cursor: not-allowed;
		}
	}

	.dropdown {
		z-index: 10;
		position: absolute;
		left: 0;
		bottom: -0.5em;
		width: 100%;
		transform: translateY(90%);
		background: white;
		padding: 0.5em;
		border: 1px var(--color-background-2) solid;
		border-radius: 0.2em;
		opacity: 0;
		pointer-events: none;

		transition: opacity 300ms, transform 300ms;

		input {
			width: 100% !important;
		}

		.options {
			max-height: 20em;
			overflow: auto;

			.option {
				padding: 0.5em;
				cursor: pointer;
				border-radius: 0.2em;

				span {
					font-weight: bold;
					text-decoration: underline;
				}

				&:hover:not(.is-selected) {
					background: #a8a8a8;
					color: white;
				}

				&.is-selected {
					cursor: default;
					opacity: 0.2;
				}
			}
		}
	}

	&.dropdown-open {
		.current {
			i {
				transform: rotate(180deg);
			}
		}

		.dropdown {
			transform: translateY(100%);
			opacity: 1;
			pointer-events: all;
		}
	}

	&.ellipsis {
		.current h4 {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
`;
