import PNTrading from 'src/pn-trading-sdk';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TrusteesTable from '../../components/customers/TrusteesTable';
import Icon from '../../elements/Icon';
import Card from '../../layout/Card';
import Page from '../../layout/Page';
import CreateTrustee from '../../layout/popups/customers/CreateTrustee';
import usePopup from '../../utils/hooks/usePopup';

export default function PageCustomer(props) {
	let cid = props.match.params.id;

	const [open] = usePopup();

	const [customer, setCustomer] = useState();
	const [originalCustomer, setOriginalCustomer] = useState();
	const [edit, setEdit] = useState({});

	useEffect(async () => {
		let customer = await PNTrading.get(`/customers/${cid}?noCache=true`);
		setCustomer(customer);
		setOriginalCustomer(customer);
	}, [cid]);

	useEffect(async () => {
		if (JSON.stringify(customer) == JSON.stringify(originalCustomer)) return;

		setOriginalCustomer(customer);
		let resp = await PNTrading.put(`/customers/${cid}`, { ...customer, id: undefined, added: undefined });
		console.log(resp);
	}, [edit]);

	if (!customer)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<>
			<TopNav></TopNav>

			<Style>
				<Card size="medium" className="customer-info">
					<div className="title-row">
						<input id="title-field" type="text" className="title" value={customer.name} disabled={!edit.name} onChange={(e) => setCustomer((c) => ({ ...c, name: e.target.value }))} />
						<Icon
							onClick={() => {
								if (!edit.name) {
									setTimeout(() => {
										let element = document.getElementById('title-field');
										element.focus();
										element.setSelectionRange(element.value.length, element.value.length);
									}, 0);
								}

								setEdit((e) => ({ ...e, name: !e.name }));
							}}
						>
							{edit.name ? 'save' : 'edit'}
						</Icon>
					</div>

					<div className="spacer s"></div>

					<div className="blCustomerNumber-row">
						<label>BL-kundnummer</label><br/>
						<input id="blCustomerNumber-field" type="text" className="blCustomerNumber" value={customer.blCustomerNumber} disabled={!edit.blCustomerNumber} onChange={(e) => setCustomer((c) => ({ ...c, blCustomerNumber: e.target.value }))} />
						<Icon
							onClick={() => {
								if (!edit.blCustomerNumber) {
									setTimeout(() => {
										let element = document.getElementById('blCustomerNumber-field');
										element.focus();
										element.setSelectionRange(element.value.length, element.value.length);
									}, 0);
								}

								setEdit((e) => ({ ...e, blCustomerNumber: !e.blCustomerNumber }));
							}}
						>
							{edit.blCustomerNumber ? 'save' : 'edit'}
						</Icon>
					</div>
				</Card>

				<Card size="full">
					<div className="row center space-between">
						<h2>Förvaltare</h2>
						<button onClick={() => open(<CreateTrustee customer={cid} />)}>Lägg till förvaltare</button>
					</div>
					<div className="spacer m"></div>
					<TrusteesTable customer={customer.id} />
				</Card>
			</Style>
		</>
	);
}

const TopNav = styled.div`
	width: 1500px;
	max-width: 100%;
	margin: auto;
	padding-left: 2rem;
	padding-right: 2rem;
	padding-top: 1.5rem;

	display: flex;
	justify-content: flex-end;
	align-items: center;

	.icon-button {
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-left: 3rem;

		.icon {
			font-size: 1.5rem;
			margin-right: 0.5rem;
		}

		span {
			font-size: 1rem;
			color: var(--color-link);
		}
	}
`;

const Style = styled(Page)`
	.customer-info {
		.title-row,
		.name-row {
			display: flex;
			align-items: center;

			input {
				padding: 0;
				border-radius: 0;
				border: none;
				max-width: calc(100% - 2.25rem);

				&:disabled {
					background: none;
					color: black;
				}
			}

			.title {
				font-size: 3rem;
			}

			.name {
				font-size: 1rem;
			}

			.icon {
				font-size: 1.25rem;
				margin-left: 1rem;
			}
		}

		.blCustomerNumber-row {
			.icon {
				margin-left: 1rem;
			}
		}

		.rows {
			border-top: 1px solid #eee;
			margin-top: 1rem;
			padding-top: 1rem;

			.row {
				color: #666;
				font-size: 0.85rem;
				margin-bottom: 0.5rem;

				a {
					color: inherit;
					margin-left: 0.3em;
				}
			}
		}
	}
`;
