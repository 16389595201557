import React from 'react';
import styled from 'styled-components';
import { UseInvoiceInvoice } from 'src/utils/api/Invoices';
import { useClosePopup } from 'src/utils/hooks/usePopup';
import Popup from 'src/layout/Popup';
import Button from 'src/elements/Button';

export default function MarkAsReady({ invoice }: { invoice: UseInvoiceInvoice }) {
	const close = useClosePopup();

	const markAsPayed = async () => {

		await invoice.update({
			status: 'unpaid',
		});

		close();
	};

	return (
		<Popup close={close} width="40em" maxWidth="30em">
			<Style>
				<h2 className="title">Markera som redo?</h2>
				<div className="spacer s"></div>
				<p>
					Fakturan kommer ändra status till "obetald" och kommer vara synlig för kunden. Vill du fortsätta?
				</p>

				<div className="spacer s"></div>

				<div className="buttons">
					<Button type="secondary" onClick={close}>
						Nej, avbryt
					</Button>
					<Button onClick={markAsPayed}>Ja, publicera</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
