import React from 'react';

export default function InvoiceStatus({ status }) {
	return {
		unpaid: <div className="badge red">OBETALD</div>,
		partially_paid: <div className="badge yellow">DELBETALD</div>,
		canceled: <div className="badge gray">AVBRUTEN</div>,
		draft: <div className="badge gray">UTKAST</div>,
		paid: <div className="badge green">BETALD</div>,
	}[status];
}
