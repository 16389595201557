import React from 'react';
import styled from 'styled-components';
import Card from '../../layout/Card';
import { UseInvoiceInvoice } from 'src/utils/api/Invoices';
import { formatPrice } from 'src/utils/Util';

export default function PaymentsCard({ invoice }: { invoice: UseInvoiceInvoice }) {

	function sort(a: any, b: any) {
		return new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime();
	}

	return (
		<Style size="medium">
			<div className="row center">
				<div className="h3">Betalningar</div>
				<div className="spacer auto"></div>
				{/* <Icon style={{fontSize: 20}} onClick={toggleEdit}>add</Icon> */}
			</div>
			<div className="spacer s"></div>

			<div className="table-wrapper">
				<table className="table" cellSpacing={0}>
					<thead>
						<tr>
							<th>Datum</th>
							<th>Summa</th>
							<th>Typ</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{invoice.payments?.sort(sort).map((payment) => (
							<tr key={payment.id}>
								<td>{new Date(payment.payment_date).toLocaleTimeString('sv-SE', {
									year: 'numeric',
									month: 'numeric',
									day: 'numeric',
									hour: 'numeric',
									minute: 'numeric',
								})}</td>
								<td>{formatPrice(payment.amount)}</td>
								<td>{payment.payment_method}</td>
								<td></td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

		</Style>
	);
}

const Style = styled(Card)`

	.table-wrapper {
		overflow-x: auto;

		table {
			width: 100%;

			thead {
				tr {
					th {
						text-align: left;
						padding: 0.5rem;
						border-bottom: 1px solid #b9b9b9;
					}
				}
			}

			tbody {
				tr {
					td {
						padding: 0.5rem;
						border-bottom: 1px solid #b9b9b9;
					}
				}
			}
		}
	}

`;
