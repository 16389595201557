import React, { useContext, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from './pages/Login';
import useResizeHandler from './utils/hooks/useResizeHandler';
import { PrivateRoute } from './utils/PrivateRoute';
import Page from './layout/Page';
import PageUsers from './pages/Users';
import PageObjects from './pages/Objects';
import PopupRenderer from './layout/PopupRenderer';
import PageObject from './pages/Object';
import PageAuctions from './pages/Auctions';
import Auctions from './utils/api/Auctions';
import PageAuction from './pages/Auction';
import PageUser from './pages/User';
import Dashboard from './pages/Dashboard';
import PageCategories from './pages/Categories';
import PageCustomers from './pages/customers/Customers';
import PageCustomer from './pages/customers/Customer';
import PageInvoices from './pages/invoices/Invoices';
import PageInvoice from './pages/invoices/Invoice';
import PageTrustee from './pages/customers/Trustee';
import PageSellers from './pages/sellers/Sellers';
import PageSeller from './pages/sellers/Seller';
import PagePayAuction from './pages/PayAuction/PayAuction';
import PageLists from './pages/lists/Lists';
import PageTickOff from './pages/lists/TickOff';
import PageTicked from './pages/lists/Ticked';
import PageExclamation from './pages/lists/Exclamation';
import PageProjectSummary from './pages/lists/ProjectSummary';
import PageAuctionSummary from './pages/lists/AuctionSummary';
import PageObjectSummary from './pages/lists/ObjectSummary';
import PageAuctionLabels from './pages/lists/AuctionLabels';
import MailTemplate from './pages/MailTemplate/MailTemplate';
import useWS from './utils/bids/useWS';
import Store from './utils/Store';
import PageEmptyLabels from './pages/lists/EmptyLabels';
import EmployeeList from './pages/employees/EmployeeList';
import EmployeePage from './pages/employees/EmployeePage';
import EmployeeReportCardPage from 'src/pages/employees/EmployeeReportCardPage';
import MePage from 'src/pages/me/MePage';
import ProfileTimePage from 'src/pages/me/ProfileTimePage';
import PageExpenses from 'src/pages/expenses/Expenses';
import PageExpense from 'src/pages/expenses/Expense';
import PageCustomerInvoices from 'src/pages/customerInvoices/CustomerInvoices';
import PageCustomerInvoice from 'src/pages/customerInvoices/CustomerInvoice';
import PageAccountableAuctions from 'src/pages/accounting/AccountableAuctions';
import PageAccountableAuction from 'src/pages/accounting/AccountableAuction';

export const Context = React.createContext();

export default function App() {
	const [loading, setLoading] = useState(false);

	const [auctions, setAuctions] = useState([]);
	const [popup, setPopup] = useState();

	// If a popup is opened by another popup it will be pushed to this stack.
	const [popups, setPopups] = useState([]);

	const load = async () => {
		setLoading(true);
		setAuctions(await Auctions.list());
		setLoading(false);
	};

	useEffect(() => {
		load();
	}, []);

	useResizeHandler();

	const ws = useWS()

	return (
		<Store>
			<Context.Provider
				value={{
					ws,
					auctions,
					setAuctions,
					popup,
					setPopup,
					popups,
					setPopups,
					load,
					loading,
					setLoading,
				}}
			>
				<Router>
					<Switch>
						<Route path="/login" component={Login} />

						<PrivateRoute path="/invoices/:id" component={PageInvoice} />
						<PrivateRoute path="/invoices" component={PageInvoices} />

						<PrivateRoute path="/sellers/:id" component={PageSeller} />
						<PrivateRoute path="/sellers" component={PageSellers} />

						<PrivateRoute path="/customers/:id/trustees/:trustee_id" component={PageTrustee} />
						<PrivateRoute path="/customers/:id" component={PageCustomer} />
						<PrivateRoute path="/customers" component={PageCustomers} />

						<PrivateRoute path="/users/:id" component={PageUser} />
						<PrivateRoute path="/users" component={PageUsers} />

						<PrivateRoute path="/objects/:id" component={PageObject} />
						<PrivateRoute path="/objects" component={PageObjects} />

						<PrivateRoute path="/categories/:category+" component={PageCategories} />
						<PrivateRoute path="/categories" component={PageCategories} />

						<PrivateRoute path="/lists/labels/:id" component={PageAuctionLabels} />
						<PrivateRoute path="/lists/labels" component={PageEmptyLabels} />
						<PrivateRoute path="/lists/object-summary/:id" component={PageObjectSummary} />
						<PrivateRoute path="/lists/auction-summary/:id" component={PageAuctionSummary} />
						<PrivateRoute path="/lists/project-summary/:id" component={PageProjectSummary} />
						<PrivateRoute path="/lists/exclamation/:id" component={PageExclamation} />
						<PrivateRoute path="/lists/ticked/:id" component={PageTicked} />
						<PrivateRoute path="/lists/tick-off/:id" component={PageTickOff} />
						<PrivateRoute path="/lists" component={PageLists} />
						
						<PrivateRoute path="/expenses/:id" component={PageExpense} />
						<PrivateRoute path="/expenses" component={PageExpenses} />
						
						<PrivateRoute path="/redovisning/auction/:id" component={PageAccountableAuction} />
						<PrivateRoute path="/redovisning/auction" component={PageAccountableAuctions} />
						
						<PrivateRoute path="/customer-invoices/:id" component={PageCustomerInvoice} />
						<PrivateRoute path="/customer-invoices" component={PageCustomerInvoices} />

						<PrivateRoute path="/me/time" component={ProfileTimePage} />
						<PrivateRoute path="/me" component={MePage} />

						<PrivateRoute path="/employees/:id/report-card" component={EmployeeReportCardPage} lockTo={['employee_admin']} />
						<PrivateRoute path="/employees/:id" component={EmployeePage} lockTo={['employee_admin']} />
						<PrivateRoute path="/employees" component={EmployeeList} lockTo={['employee_admin']} />
						
						<PrivateRoute path="/mail-template" component={MailTemplate} />

						<PrivateRoute path="/pay-auction/:id" component={PagePayAuction} />

						<PrivateRoute path="/auctions/:id" component={PageAuction} />
						<PrivateRoute path="/auctions" component={PageAuctions} />

						<PrivateRoute exact path="/" component={Dashboard} />

						<PrivateRoute
							path="*"
							component={() => (
								<Page>
									<h2>404 Not found</h2>
								</Page>
							)}
						/>
					</Switch>

					<PopupRenderer />
				</Router>
			</Context.Provider>
		</Store>
	);
}

export function useSocket() {
	const { ws } = useContext(Context)
	return ws;
}