import { useEffect, useState } from 'react';
import Accounting, { AccountableAuctionsAuction, GetAccountableAuctionsArgs } from '../Accounting';


export default function useAccountableAuctions(args?: GetAccountableAuctionsArgs) {
    const [auctions, setAuctions] = useState<AccountableAuctionsAuction[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setLoading(true);
        setError(null);
        Accounting.getAccountableAuctions(args)
            .then(setAuctions)
            .catch(e => setError(e.message))
            .finally(() => setLoading(false));
    }, [args?.hasAccountable, args?.limit]);

    return { auctions, loading, error };
}